// Generated by Framer (705c3f6)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["lvR0BiObE", "TDCOFuDlJ", "LanbmTaRH", "mUrtGBKfe"];

const serializationHash = "framer-n0k83"

const variantClassNames = {LanbmTaRH: "framer-v-1xxazc3", lvR0BiObE: "framer-v-7xum0f", mUrtGBKfe: "framer-v-5df7ho", TDCOFuDlJ: "framer-v-1443q7n"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {duration: 0, type: "tween"}

const transition2 = {delay: 0, duration: 4, ease: [0, 0, 1, 1], type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Empty: "TDCOFuDlJ", Loaded: "LanbmTaRH", Loading: "lvR0BiObE", Neutral: "mUrtGBKfe"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "lvR0BiObE"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "lvR0BiObE", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppear8l2d62 = activeVariantCallback(async (...args) => {
setVariant("LanbmTaRH")
})

useOnVariantChange(baseVariant, {default: onAppear8l2d62, LanbmTaRH: undefined, mUrtGBKfe: undefined, TDCOFuDlJ: undefined})

const ref1 = React.useRef<HTMLElement>(null)

const isDisplayed = () => {
if (baseVariant === "mUrtGBKfe") return true
return false
}

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1} {...addPropertyOverrides({LanbmTaRH: {value: transition2}}, baseVariant, gestureVariant)}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-7xum0f", className, classNames)} data-framer-name={"Loading"} data-highlight layoutDependency={layoutDependency} layoutId={"lvR0BiObE"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({LanbmTaRH: {"data-framer-name": "Loaded", "data-highlight": undefined}, mUrtGBKfe: {"data-framer-name": "Neutral", "data-highlight": undefined}, TDCOFuDlJ: {"data-framer-name": "Empty", "data-highlight": undefined}}, baseVariant, gestureVariant)}><motion.div className={"framer-bqe9zj"} layoutDependency={layoutDependency} layoutId={"jDbXs6Fyr"}><motion.div className={"framer-kd69og"} layoutDependency={layoutDependency} layoutId={"TItlIhnRJ"} style={{backgroundColor: "rgb(255, 255, 255)"}}/>{isDisplayed() && (<motion.div className={"framer-orr7d"} data-framer-name={"Static"} layoutDependency={layoutDependency} layoutId={"dtV7c2J2Y"} style={{backgroundColor: "rgb(255, 255, 255)"}}/>)}<motion.div className={"framer-1vbs4ed"} layoutDependency={layoutDependency} layoutId={"NgmzynHCW"} style={{backgroundColor: "rgb(255, 255, 255)", opacity: 0.4}}/></motion.div></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-n0k83.framer-1lu726t, .framer-n0k83 .framer-1lu726t { display: block; }", ".framer-n0k83.framer-7xum0f { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 1px; justify-content: flex-start; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: 27px; }", ".framer-n0k83 .framer-bqe9zj { align-content: center; align-items: center; display: flex; flex: 1 0 0px; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 1px; justify-content: flex-start; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 1px; }", ".framer-n0k83 .framer-kd69og { flex: none; height: 1px; overflow: hidden; position: relative; width: 0%; }", ".framer-n0k83 .framer-orr7d { bottom: 0px; flex: none; left: 0px; overflow: hidden; position: absolute; top: 0px; width: 27px; z-index: 3; }", ".framer-n0k83 .framer-1vbs4ed { bottom: 0px; flex: none; overflow: hidden; position: absolute; right: 0px; top: 0px; width: 100%; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-n0k83.framer-7xum0f, .framer-n0k83 .framer-bqe9zj { gap: 0px; } .framer-n0k83.framer-7xum0f > *, .framer-n0k83 .framer-bqe9zj > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-n0k83.framer-7xum0f > :first-child, .framer-n0k83 .framer-bqe9zj > :first-child { margin-left: 0px; } .framer-n0k83.framer-7xum0f > :last-child, .framer-n0k83 .framer-bqe9zj > :last-child { margin-right: 0px; } }", ".framer-n0k83.framer-v-1xxazc3 .framer-kd69og { width: 100%; }", ".framer-n0k83.framer-v-5df7ho .framer-orr7d { right: 0px; width: unset; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 1
 * @framerIntrinsicWidth 27
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"TDCOFuDlJ":{"layout":["fixed","fixed"]},"LanbmTaRH":{"layout":["fixed","fixed"]},"mUrtGBKfe":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerCVllZD9fO: React.ComponentType<Props> = withCSS(Component, css, "framer-n0k83") as typeof Component;
export default FramerCVllZD9fO;

FramerCVllZD9fO.displayName = "Stories - Home Line";

FramerCVllZD9fO.defaultProps = {height: 1, width: 27};

addPropertyControls(FramerCVllZD9fO, {variant: {options: ["lvR0BiObE", "TDCOFuDlJ", "LanbmTaRH", "mUrtGBKfe"], optionTitles: ["Loading", "Empty", "Loaded", "Neutral"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerCVllZD9fO, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})